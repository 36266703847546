import React from 'react'
import aboutVideo from "../assets/img/aboutVideo.mp4"
import achieve from "../assets/img/acheive.png"
import code from "../assets/img/code.png"
import table from "../assets/img/table.png"
import IPhone from "../assets/img/IPhone.png"
import nighttable from "../assets/img/nighttable.png"
import goal from "../assets/img/goals.png"
// import dussera1 from "../assets/img/dussera1.png"
// import dussera2 from "../assets/img/dussera2.png"
// import dussera3 from "../assets/img/dussera3.png"
// import anni1 from "../assets/img/anni1.jpg"
// import anni2 from "../assets/img/rksir.jpg"
// import anni3 from "../assets/img/10thcake.png"
import Footer from './footer'



// const eventPics = [{ id: 3, img: [dussera1, dussera2, dussera3] }, { id: 1, img: [anni1, anni2, anni3] }]

const About = () => {
    // const [show, setshow] = useState(eventPics[1].id)
    // const onChangePic = (id) => setshow(id)
    // const filterPics = eventPics.filter((x) => x.id === show)
    // const selectPics = eventPics.filter((x) => x.id === show)[0]
    return (
        <>
            <section className="position-relative">
                <video src={aboutVideo} autoPlay loop muted className="aboutVideo" />
                <div className="welcome">
                </div>
                <div className="position-absolute z-5 d-flex flex-column align-items-center justify-content-center w-100 welContent" >
                    <div>
                        <div>
                            <h1 className="aboutHead">Welcome to <span className="aboutKode">KodeCorp</span> - Where Passion Meets Innovation</h1>
                            <hr className="horizon" />
                        </div>
                        <p className="aboutPara">For over a decade, KodeCorp has been a beacon of technological excellence and innovation. Our journey,<br /> powered by a deep-seated passion for tech, has seen us partner with numerous mid to large-sized <br />companies, revolutionizing their digital landscapes with our cutting-edge solutions.</p>
                    </div>
                </div>
            </section>
            <div className="d-flex flex-column align-items-center justify-content-center pt-4">
                <div className="aboutDriven mb-3" >
                    <h1 className="drivenHead">Driven By <span className="drivenSpan">Passion</span> <br /> Defined By <span className="drivenSpan">Excellence</span></h1>
                    <img src={achieve} alt="achieve" className="achieveImg" />
                </div>
                <div className="decade d-flex flex-column align-items-center ">
                    <h1 className="templateHead">A Decade Of Dominance</h1>
                    <p className="templatePara">11 years of relentless pursuit of technological mastery and client success stories.</p>
                    <img src={code} alt="achieve" className="codeImg" />
                </div>
                <hr className="seperate" />
                <div className="decade d-flex flex-column align-items-center ">
                    <h1 className="templateHead">Versatility In Tech</h1>
                    <p className="templatePara text-center">From seamless web experiences to robust mobile apps, and from intricate data analytics to advanced <br /> data science, our portfolio is as diverse as it is deep.</p>
                    <img src={IPhone} alt="achieve" className="codeImg" />
                </div>
                <hr className="seperate" />
                <div className="decade d-flex flex-column align-items-center ">
                    <h1 className="templateHead">Unwavering Commitment</h1>
                    <p className="templatePara text-center">We're not just tech experts, we're enthusiasts committed to elevating your  business with bespoke solutions.</p>
                    <img src={table} alt="achieve" className="codeImg" />
                </div>
                <hr className="seperate" />
                <div className="decade d-flex flex-column align-items-center ">
                    <h1 className="templateHead">Efficiency And Economy</h1>
                    <p className="templatePara text-center">We break the stereotypes - delivering top-tier tech solutions rapidly and  within your financial comfort zone.</p>
                    <img src={nighttable} alt="achieve" className="codeImg" />
                </div>
                <div className="aboutDriven  py-3 mb-5" >
                    <img src={goal} alt="goal" className="goalImg" />
                    <div className="goalContent">
                        <h1 className="drivenHead" >Embark On A Journey With <span className="drivenSpan">Kodecorp</span> </h1>
                        <p className="goalPara"> Where Every Challenge Is Met With An Innovative Solution,<br /> Crafted With Passion And Precision.</p>
                        <p className="goalPara1"> Your vision is our canvas, and our passion for technology is the brush. At KodeCorp, we don't just build digital solutions; we craft digital legacies. Join us in this journey of innovation and let's sculpt the future together.</p>
                    </div>
                </div>
                {/* <div className="text-center" style={{ width: "80%" }} >
                    <h1 className="templateHead">Events Gallery</h1>
                    <p className="templatePara">Come And Be A Part Of Our Success.</p>
                    <TabCarousel onChangePic={onChangePic} />
                </div> */}
            </div >
            {/* <div className="gallarypics ms-3 mb-5">
                {filterPics.length === 0 ? <div className="gallary-img"></div> : selectPics.img.map((x) => (<img src={x} alt="duss" className="gallary-img" />))}
            </div> */}
            <Footer show={true} />
        </>
    )
}

export default About