import React from "react";
import { HiChevronDoubleRight } from "react-icons/hi";
import upImage from "../../assets/img/upimage.png";
import downImage from "../../assets/img/downImage.png";
import CarouselItem from "./../carousels/aboutcarousel";
import { useNavigate } from "react-router-dom";
const numerical = [
  // { id: 1, count: 150, text: "Active Clients" },

  { id: 2, count: 120, text: "Projects Done" },
  { id: 3, count: 25, text: "Team Advisors" },
  { id: 4, count: 55, text: "Glorious Team" },
];
const Section2 = () => {
  const navigate = useNavigate();
  return (
    <section className="main-section sub-section  bg-container position-relative" id="section2">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img flex-sm-{grow|shrink}-1" src={upImage} alt="up" />
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={downImage} alt="down" />
      </div>
      <div className="content-card card-1 col-12">
        <div className="about-carousel col-10 col-md-6 col-lg-4 col-xl-3">
          <CarouselItem />
        </div>

        <div className="about-content text-white col-12 col-md-8 col-lg-5">
          <p>About</p>
          <h1 className="about-head">
            About <span className="kode">KodeCorp</span>
          </h1>
          <p className="about-para">
            For over a decade, KodeCorp has been a beacon of technological excellence and innovation. Our journey, powered by a deep-seated passion for tech, has seen us partner with numerous mid to large-sized companies, revolutionizing their digital landscapes with our cutting-edge solutions.
          </p>
          <div className="active-cilents">
            {numerical.map((each, idx) => (
              <div key={idx} className="flex flex-column justify-content-center align-items-center text-center item-client p-1">
                <h6 className="sec-num">{`${each.count}+`}</h6>
                <p className="sec-text">{each.text}</p>
              </div>
            ))}
          </div>
          <button className="explore-btn btn_sec_2 px-4" onClick={() => navigate("/about")}>
            <span>Explore More</span>{" "}
            <span className="btn_arrow">
              <HiChevronDoubleRight />
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Section2;
