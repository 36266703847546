import React from "react";
import "../../styles/style.css";

import truckvideo from "../../assets/img/truckvideo.mp4";
// import anni1 from "../../assets/img/Anniversary1.png"
// import anni2 from "../../assets/img/Anniversary2.png"
// import anni3 from "../../assets/img/Anniversary3.png"

import { useNavigate } from "react-router-dom";
import { HiChevronDoubleRight } from "react-icons/hi";

const Section1 = () => {
  const navigate = useNavigate();
  return (
    <section className="main main-section position-relative" id="section0">
      <video src={truckvideo} autoPlay loop muted />
      <div className="main-text-cont">
        <h1 className="heading">Innovating Success at KodeCorp</h1>
        <p className="para fw-300">
          Elevating startups to Fortune 500s with custom tech solutions and unparalleled teamwork.
          <br />
          Join us on a journey of growth and innovation.
        </p>
        <button className="explore-btn btn_sec_1 px-4" onClick={() => navigate("/about")}>
          <span>Explore More</span>{" "}
          <span className="btn_arrow">
            <HiChevronDoubleRight />
          </span>
        </button>
      </div>
    </section>
  );
};

export default Section1;
