import { Link, useNavigate } from "react-router-dom";
import kc_cso1 from "../../assets/img/kc_cso1.png";
import kc_cso2 from "../../assets/img/kc_cso2.png";
import kc_cso3 from "../../assets/img/kc_cso3.png";
import kc_cso4 from "../../assets/img/kc_cso4.png";
import kc_cso5 from "../../assets/img/kc_cso5.png";
import cspartner from "../../assets/img/cspartner.png";
import ben1 from "../../assets/img/ben1.png";
import ben2 from "../../assets/img/ben2.png";
import ben3 from "../../assets/img/ben3.png";
import approach4 from "../../assets/img/approach4.png";
import Cardimg21 from "../../assets/img/Cardimg21.png";
import Cardimg22 from "../../assets/img/Cardimg22.png";
import Cardimg23 from "../../assets/img/Cardimg23.png";
import Cardimg24 from "../../assets/img/Cardimg24.png";
import Cardimg25 from "../../assets/img/Cardimg25.png";
import Cardimg26 from "../../assets/img/Cardimg26.png";
import Cardimg31 from "../../assets/img/Cardimg31.png";
import Cardimg32 from "../../assets/img/Cardimg32.png";
import Cardimg33 from "../../assets/img/Cardimg33.png";
import Cardimg34 from "../../assets/img/Cardimg34.png";
import Cardimg35 from "../../assets/img/Cardimg35.png";
import Cardimg36 from "../../assets/img/Cardimg36.png";
import Cardimg37 from "../../assets/img/Cardimg37.png";
import Cardimg38 from "../../assets/img/Cardimg38.png";
import Cardimg39 from "../../assets/img/Cardimg39.png";
import Cardimg310 from "../../assets/img/Cardimg310.png";
import Cardimg311 from "../../assets/img/Cardimg311.png";
import Cardimg312 from "../../assets/img/Cardimg312.png";
import Cardimg313 from "../../assets/img/Cardimg313.png";
import Cardimg314 from "../../assets/img/Cardimg314.png";
import Cardimg315 from "../../assets/img/Cardimg315.png";
import Cardimg316 from "../../assets/img/Cardimg316.png";
import Cardimg317 from "../../assets/img/Cardimg317.png";
import Cardimg318 from "../../assets/img/Cardimg318.png";
import Cardimg319 from "../../assets/img/Cardimg319.png";
import Cardimg320 from "../../assets/img/Cardimg320.png";
import Cardimg321 from "../../assets/img/Cardimg321.png";
import Cardimg322 from "../../assets/img/Cardimg322.png";
import Cardimg323 from "../../assets/img/Cardimg323.png";
import aboutVideo from "../../assets/img/aboutVideo.mp4";
import calling from "../../assets/img/callng.png";
import Footer from "../footer";
import ai from "../../assets/img/ai.png";
import iot from "../../assets/img/iot.png";
import block from "../../assets/img/block.png";
import cloud from "../../assets/img/cloud.png";
import bigdata from "../../assets/img/bigdata.png";
import vr from "../../assets/img/vr.png";

import csoption1 from "../../assets/img/csoption1.png";
import csoption2 from "../../assets/img/csoption2.png";
import csoption3 from "../../assets/img/csoption3.png";
import csoption4 from "../../assets/img/csoption4.png";
import csoption5 from "../../assets/img/csoption5.png";
import csoption6 from "../../assets/img/csoption6.png";
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi";
import Slider from "react-slick";

const ServiceData = [
  {
    id: 1,
    heading: <h1 className="card-heading">Cloud consulting</h1>,
    Paragraph: <p className="card-paragraph">Auditing your current IT infrastructure and business processes, analyzing your cloud adoption needs, and preparing a feasibility study and business case are all services we provide.</p>,
    img: kc_cso1,
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">.Net</button>
        <button className="serv-btn">Angular</button>
        <button className="serv-btn">React</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">AWS</button>
        <button className="serv-btn">Azure</button>
        <button className="serv-btn">Google Cloud</button>
      </div>
    ),
    Explore: "Explore More",
  },
  {
    id: 2,
    heading: <h1 className="card-heading">Cloud migration</h1>,
    Paragraph: <p className="card-paragraph">With us, you can modernize your application or data storage and perform fast, smooth, and reliable migrations without experiencing unplanned downtime.</p>,
    img: kc_cso2,
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">React</button>
        <button className="serv-btn">React Native</button>
        <button className="serv-btn">Node .JS</button>
      </div>
    ),
    Explore: "Explore More",
  },
  {
    id: 3,
    heading: <h1 className="card-heading">Cloud development</h1>,
    Paragraph: <p className="card-paragraph">Our team designs, builds, tests, deploys, and supports apps and data warehouses which are fully suited to the cloud's specific capabilities and constraints.</p>,
    img: kc_cso3,
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">React Native</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">React JS</button>
        <button className="serv-btn">IOS</button>
        <button className="serv-btn">Android</button>
      </div>
    ),
    Explore: "Explore More",
  },
  {
    id: 4,
    heading: <h1 className="card-heading">Cloud optimization</h1>,
    Paragraph: <p className="card-paragraph">We review your cloud environment and ensure you are using the right cloud services for your business needs. Additionally, we pause or terminate redundant cloud services, rightsize cloud resources, and consider using Reserved and Spot instances.</p>,
    img: kc_cso4,
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">Data</button>
        <button className="serv-btn">Angular</button>
        <button className="serv-btn">React</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">AWS</button>
      </div>
    ),
    Explore: "Explore More",
  },
  {
    id: 5,
    heading: <h1 className="card-heading">Cloud Innovation</h1>,
    Paragraph: <p className="card-paragraph">We review your cloud environment and ensure you are using the right cloud services for your business needs. Additionally, we pause or terminate redundant cloud services, rightsize cloud resources, and consider using Reserved and Spot instances.</p>,
    img: kc_cso5,
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">AI</button>
        <button className="serv-btn">Amazon Web Services</button>
        <button className="serv-btn">Android</button>
      </div>
    ),
    Explore: "Explore More",
  },
];

const ServiceData1 = [
  {
    id: 1,
    img: ai,
    heading: <p className="card-heading1">Artificial Intelligence</p>,
  },
  {
    id: 2,
    img: iot,
    heading: <p className="card-heading1">Internet of Things</p>,
  },
  {
    id: 3,
    img: block,
    heading: <p className="card-heading1">Blockchain</p>,
  },
  {
    id: 4,
    img: cloud,
    heading: <p className="card-heading1">Cloud Computing</p>,
  },
  {
    id: 5,
    img: bigdata,
    heading: <p className="card-heading1">Big Data</p>,
  },
  {
    id: 6,
    img: vr,
    heading: <p className="card-heading1">Augmented Reality</p>,
  },
];

const ServiceData2 = [
  {
    id: 1,
    img: Cardimg31,
    heading: <h1 className="card-heading2">HTML</h1>,
  },
  {
    id: 2,
    img: Cardimg32,
    heading: <h1 className="card-heading2">CSS</h1>,
  },
  {
    id: 3,
    img: Cardimg33,
    heading: <h1 className="card-heading2">Bootstrap</h1>,
  },
  {
    id: 4,
    img: Cardimg34,
    heading: <h1 className="card-heading2">JavaScript</h1>,
  },
  {
    id: 5,
    img: Cardimg35,
    heading: <h1 className="card-heading2">jQuery</h1>,
  },
  {
    id: 6,
    img: Cardimg36,
    heading: <h1 className="card-heading2">Semantic UI</h1>,
  },
  {
    id: 7,
    img: Cardimg37,
    heading: <h1 className="card-heading2">React</h1>,
  },
  {
    id: 8,
    img: Cardimg38,
    heading: <h1 className="card-heading2">Angular</h1>,
  },
  {
    id: 9,
    img: Cardimg39,
    heading: <h1 className="card-heading2">Node.Js</h1>,
  },
  {
    id: 10,
    img: Cardimg310,
    heading: <h1 className="card-heading2">Ember.Js</h1>,
  },
  {
    id: 11,
    img: Cardimg311,
    heading: <h1 className="card-heading2">Next.Js</h1>,
  },
];

const ServiceData3 = [
  {
    id: 1,
    img: Cardimg312,
    heading: <h1 className="card-heading2">Java</h1>,
  },
  {
    id: 2,
    img: Cardimg313,
    heading: <h1 className="card-heading2">ASP .NET Core</h1>,
  },
  {
    id: 3,
    img: Cardimg314,
    heading: <h1 className="card-heading2">.Net</h1>,
  },
  {
    id: 4,
    img: Cardimg315,
    heading: <h1 className="card-heading2">ASP .Net</h1>,
  },
  {
    id: 5,
    img: Cardimg316,
    heading: <h1 className="card-heading2">C#</h1>,
  },
  {
    id: 6,
    img: Cardimg317,
    heading: <h1 className="card-heading2">Node.Js</h1>,
  },
  {
    id: 7,
    img: Cardimg318,
    heading: <h1 className="card-heading2">Python</h1>,
  },
  {
    id: 8,
    img: Cardimg319,
    heading: <h1 className="card-heading2">Ruby</h1>,
  },
  {
    id: 9,
    img: Cardimg320,
    heading: <h1 className="card-heading2">PHP</h1>,
  },
  {
    id: 10,
    img: Cardimg321,
    heading: <h1 className="card-heading2">COBOL</h1>,
  },
  {
    id: 11,
    img: Cardimg322,
    heading: <h1 className="card-heading2">Next.Js</h1>,
  },
  {
    id: 11,
    img: Cardimg323,
    heading: <h1 className="card-heading2">Nest.Js</h1>,
  },
];

const testimonials = [
  {
    id: 1,
    image: csoption1,
    head: <h6>Scalability</h6>,
    desc: <p>Cloud services offer scalability, meaning you can easily adjust your computing resources based on your needs.</p>,
  },
  {
    id: 2,
    image: csoption2,
    head: <h6>Cost Efficiency</h6>,
    desc: <p>Cloud services follow a pay-as-you-go model, allowing businesses to pay only for the resources they use.</p>,
  },
  {
    id: 3,
    image: csoption3,
    head: <h6>Reliability and Availability:</h6>,
    desc: <p>Cloud service providers often have multiple data centers in different locations, ensuring redundancy and high availability.</p>,
  },
  {
    id: 4,
    image: csoption4,
    head: <h6>Security</h6>,
    desc: <p>Cloud service providers invest heavily in security measures to protect their infrastructure and customer data.</p>,
  },
  {
    id: 5,
    image: csoption5,
    head: <h6>Flexibility and Mobility</h6>,
    desc: <p>Cloud services provide users with the ability to access their data and applications from anywhere with an internet connection.</p>,
  },
  {
    id: 6,
    image: csoption6,
    head: <h6>Innovation and Integration</h6>,
    desc: <p>Cloud services often offer a wide range of built-in tools, services, and APIs that enable businesses to develop and deploy applications faster.</p>,
  },
];

const testimonials1 = [
  {
    id: 1,
    image: ben1,
    head: <h6>Reduced migration cost</h6>,
    desc: <p className="text-center card-paragraph">To reduce migration costs and avoid extra re-development efforts, we develop pragmatic migration strategies for each component of the infrastructure.</p>,
  },
  {
    id: 2,
    image: ben2,
    head: <h6>High development speed</h6>,
    desc: <p className="text-center card-paragraph">Crafting a DevOps strategy that aligns with your business needs and technological landscape.</p>,
  },
  {
    id: 3,
    image: ben3,
    head: <h6>Optimized monthly cloud costs</h6>,
    desc: <p className="text-center card-paragraph">Our team designs optimal resource orchestration patterns (including auto-scaling for peak times) and selects the most appropriate cloud services for your particular needs.</p>,
  },
  {
    id: 4,
    image: approach4,
    head: <h6>Continuous Learning & Improvement</h6>,
    desc: <p className="text-center card-paragraph">We believe in continuous evolution. Our solutions are designed to adapt and improve as your business and the data landscape evolve.</p>,
  },
];
function SampleNextArrow(props) {
  const { onClick } = props;
  return <HiArrowNarrowRight className="serv_arrows slick-right " onClick={onClick} />;
}
function SamplePrevArrow(props) {
  const { onClick } = props;
  return <HiArrowNarrowLeft className="serv_arrows slick-left" onClick={onClick} />;
}
const settings = {
  dots: false,
  infinite: true,
  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 2000,
  autoplaySpeed: 1500,
  cssEase: "linear",
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
  responsive: [
    {
      breakpoint: 1366,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
        // infinite: true,
        // dots: true
      },
    },
    {
      breakpoint: 1199,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        // infinite: true,
        // dots: true,
      },
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: true,
        nextArrow: false,
        prevArrow: false,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
        nextArrow: false,
        prevArrow: false,
      },
    },
  ],
};

const Service6 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="position-relative">
        <video src={aboutVideo} autoPlay loop muted className="aboutVideo" />
        <div className="welcome"></div>
        <div className="position-absolute z-5 d-flex flex-column align-items-center justify-content-center w-100 welContent">
          <div>
            <div>
              <h1 className="aboutHead">
                KodeCorp's <span className="aboutKode">Cloud Consulting Services</span>{" "}
              </h1>
              <h3 className="text-white serv_subhead">Make the most of your cloud investments!</h3>
              <hr className="horizon" />
            </div>
            <p className="aboutPara">
              In addition to providing cloud development and migration services, KodeCprp has more than ten years of experience in <br></br>
              cloud development and migration. In addition to designing cloud adoption strategies and defining cost optimization tactics, <br></br>
              we develop efficient cloud-native applications, and data warehouses, and migrate legacy infrastructures to the cloud.
            </p>
          </div>
        </div>
      </div>
      <div className="main-card-section service6">
        <h1 className="aboutHead1 aboutHead1_2 mt-5 mb-3">
          KodeCorp's <span className="aboutKode1 aboutKode1_2">Cloud Service Options</span>{" "}
        </h1>
        <div className="flex card-container services">
          {ServiceData.map((each) => {
            return (
              <div className="bg-card col-lg-3 col-md-4 col-9">
                <img className="service-cardimg" src={each.img} alt="card1" />
                {each.heading}
                {each.Paragraph}
                <span className="service-explore" onClick={() => navigate("/serviceSubPage", { state: { id: each.id } })}>
                  {/* {each.Explore} */}
                </span>
              </div>
            );
          })}
        </div>
        {/* <div className="mb-3 mt-5 resp_width">
          <h1 className="service-mainheading">Emerging Technologies For Enterprise</h1>
          <p className="text-center card-paragraph">
            Intellect soft has grown into an international contestant in enterprise software engineering<br></br> with wide-ranging expertise in all the areas needed for reliable software development.
          </p>
          <div className="d-flex flex-row flex-wrap">
            {ServiceData1.map((each) => {
              return (
                <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                  <div className="bg-card3 mb-3">
                    <img className="service-cardimg3" src={each.img} alt="card1" />
                  </div>
                  {each.heading}
                </div>
              );
            })}
          </div>
        </div> */}

        <h1 className="aboutHead1 mt-5 mb-2">
          Why Choose <span className="aboutKode1">Cloud Service Options</span>{" "}
        </h1>
        <p className="text-center card-paragraph">
          The integration of emerging technologies, supported by the power of the cloud, is a pivotal driver of innovation in today's business landscape. <br></br>By embracing these new technologies, organizations can effectively align with their business strategies.
        </p>
        <div className="kc_slider mb-4">
          <Slider {...settings}>
            {testimonials.map((testimonial, idx) => (
              <div className="testimionals" key={idx}>
                <div className="mx-2 kc-item ">
                  <figure class="wp-caption">
                    <img src={testimonial.image} alt={testimonial.name} />
                    <figcaption class="wp-caption-text">
                      {testimonial.head}
                      {testimonial.desc}
                    </figcaption>
                  </figure>
                </div>
              </div>
            ))}
          </Slider>
        </div>

        <div className="flex card-container success mt-3 mb-3">
          <div className="col-lg-4 col-md-4 col-sm-12">
            <img src={cspartner} alt="success_img" />
          </div>
          <div className="col-lg-8 col-md-8 col-sm-12 p-2">
            <h1 className="aboutHead1 mb-2">
              Partner with kodeCorp <span className="aboutKode1">Cloud Consulting Services.</span>{" "}
            </h1>
            <p className="card-paragraph">Step into a world of accelerated innovation, efficiency, and quality by embracing the power of Cloud Consulting Services. Contact Kode Corp today and let us help you unlock your business's full potential with the cloud.</p>
          </div>
        </div>

        <h1 className="aboutHead1 aboutHead1_2 mt-5 mb-2">
          Benefits of <span className="aboutKode1 aboutKode1_2">Cloud Consulting Services</span>{" "}
        </h1>
        <div className="kc_slider mb-4">
          <Slider {...settings}>
            {testimonials1.map((testimonial, idx) => (
              <div className="bg-card processcard col-lg-3 col-md-4 col-9">
                <div className="process-card">
                  <img className="process-cardimg" src={testimonial.image} alt="card1" />
                </div>
                {testimonial.head}
                {testimonial.desc}
              </div>
            ))}
          </Slider>
        </div>

        {/* <div className="mb-5 resp_width">
          <h1 className="service-mainheading1">Our Tech Stack</h1>
          <span className="service-mainheading2">Frontend</span>
          <div className="flex card-container1">
            {ServiceData2.map((each) => {
              return (
                <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                  <div className="bg-card2 ">
                    {each.heading}
                    <img className="service-cardimg2" src={each.img} alt="card1" />
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <div className="mb-5 resp_width">
          <span className="service-mainheading2">Backend</span>
          <div className="flex card-container1">
            {ServiceData3.map((each) => {
              return (
                <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6">
                  <div className="bg-card2 ">
                    {each.heading}
                    <img className="service-cardimg2" src={each.img} alt="card1" />
                  </div>
                </div>
              );
            })}
          </div>
        </div> */}

        {/* <div className="flex card-container">
          {ServiceData1.map((each) => {
            return (
              <div className="bg-card1 col-lg-3 col-md-4 col-9">
                <img className="service-cardimg1" src={each.img} alt="card1" />
                {each.heading}
                {each.Paragraph}
                {each.button}
              </div>
            );
          })}
        </div> */}
        {/* <div style={{ width: "78%" }} className="position-relative mb-4 d-flex justify-content-center">
          <div className="col-12 col-lg-6 mobile-contact service-Contactinfo contact-call mt-5">
            <div className="position-relative">
              <img src={calling} alt="call" width={80} className="msg-icon"></img>
              <h4 className="service-contact">Contact us at</h4>
              <h4 className="service-numberinfo">+91 (40) 2956 2666, +91 630 268 489</h4>
            </div>
          </div>
        </div> */}
      </div>
      <Footer show={true} />
    </>
  );
};

export default Service6;
