import React from "react";
import pagedown4 from "../../assets/img/pagedown3.png";
import pageup4 from "../../assets/img/pageup3.png";
import { HiChevronDoubleRight } from "react-icons/hi";
import { ServiceCarousel } from "./../carousels/servicecarousel";
import { useAccordionButton } from "react-bootstrap";
import { useNavigate } from "react-router";

const Section4 = () => {
  const navigate = useNavigate();
  return (
    <section className="main-section  sub-section bg-container4 position-relative" id="section4">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img align-self-start flex-sm-{grow|shrink}-1" src={pageup4} alt="up" />
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={pagedown4} alt="down" />
      </div>
      <div className="content-card card-4">
        <div className="service-content text-white ">
          <p>Services</p>
          <h1 className="about-head text-center">
            {" "}
            Real-time monitoring of your{" "}
            <span className="kode kode-3">
              Infrastructure
              <br /> Branded
            </span>{" "}
            Digital Solutions
          </h1>
          <ServiceCarousel />
          <p className="about-para  service-para">KodeCorp is all about a team of young, dedicated and highly skilled professionals, whose work over the years speaks for itself. Established in the year 2013.</p>
          <button className="explore-btn btn_sec_4 px-4" onClick={() => navigate("/services")}>
            <span>Explore More</span>{" "}
            <span className="btn_arrow">
              <HiChevronDoubleRight />
            </span>
          </button>
        </div>
      </div>
    </section>
  );
};

export default Section4;
