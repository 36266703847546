import { Route, Routes, useLocation } from "react-router-dom";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/style.css";
import Home from "./components/Home/Home";
import About from "./components/About";
import React, { useEffect } from "react";
import Header from "./components/Header";
import Contact from "./components/Contact";
import ServiceSubPage from "./components/ServiceSubPage";
import Service1 from "./components/services/Service1";
import Service2 from "./components/services/Service2";
import Service3 from "./components/services/Service3";
import Service4 from "./components/services/Service4";
import Service5 from "./components/services/Service5";
import Service6 from "./components/services/Service6";
function App() {
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <>
      {location.pathname !== "/" && <Header />}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/enterprise-software-development-company" element={<Service1 />} />
        {/* <Route index path="service1" element={<Services />} /> */}
        <Route path="/mobile-app-development-company" element={<Service2 />} />
        <Route path="/data-science-consulting-services" element={<Service3 />} />
        <Route path="/generative-ai-consulting-company" element={<Service4 />} />
        <Route path="/devops-consulting-services" element={<Service5 />} />
        <Route path="/cloud-consulting-services" element={<Service6 />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/serviceSubPage" element={<ServiceSubPage />} />
      </Routes>
    </>
  );
}

export default App;
