import React from "react";
import pagedown from "../../assets/img/pagedown5.png";
import pageup from "../../assets/img/pageup5.png";
import { HiChevronDoubleRight } from "react-icons/hi";
import serviceImg from "../../assets/img/image4.png";
const Section6 = () => {
  return (
    <section className="main-section  sub-section bg-container6 position-relative" id="section6">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img align-self-start flex-sm-{grow|shrink}-1" src={pageup} alt="up" />
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={pagedown} alt="down" />
      </div>
      <div className="content-card card-6 col-12 last-card">
        <div className="col-10 col-md-8 col-lg-5 d-flex justify-content-center align-items-center">
          <img src={serviceImg} alt="service" className="service-img " />
        </div>
        <div className="about-content text-white col-10 col-md-8 col-lg-4 last-content">
          <p>About</p>
          <h1 className="about-head">
            Index Of Technology For The <span className="kode kode-5">Services Industry</span>
          </h1>
          <p className="about-para">
            KodeCorp is all about a team of young, dedicated and highly skilled professionals, whose work over the years speaks for itself. Established in the year 2013, the Kode team has been delivering enterprise class sustainable software solutions for corporate businesses across the globe.
          </p>
          {/* <button className="explore-btn btn_sec_5 px-4"><span>Explore More</span> <span className="btn_arrow"><HiChevronDoubleRight /></span></button> */}
        </div>
      </div>
    </section>
  );
};

export default Section6;
