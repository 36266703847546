import React from "react";
import ChatBot from "react-simple-chatbot";
import { ThemeProvider } from "styled-components";

const data = [
  {
    q: "Hello",
    a: "Happy to connect us Welcome to Kodecorp Private Limitied, how would we like to help you?",
  },
  {
    q: "Can you provide more information about your procurement and settlement platforms?",
    a:
      "Our procurement and settlement platforms are state-of-the-art solutions that help streamline the procurement process and automate the accounts payable process. They are designed to accelerate the fuel procurement cycle and reduce costly matching, auditing, reconciliation, and credit and re-bill headaches.",
  },
  {
    q: "Do you offer fuel clearing house automation?",

    a:
      "Yes, we offer fuel clearing house automation services. Our clearing house for fuel transactions automates the accounts payable process, reducing manual work and accelerating the fuel procurement cycle.",
  },
  {
    q: "How long has KodeCorp been in business?",
    a:
      "KodeCorp has been in business since 2013. We have over 11 years of experience in delivering top-tier tech solutions and partnering with mid to large-sized companies to revolutionize their digital landscapes.",
  },
  {
    q: "Can you tell me more about your partnership with Veri-Fuel?",
    a:
      "We are proud to be Veri-Fuel's trusted technology partner. We provide services in IoT, web, and mobile applications to support Veri-Fuel's end-to-end enterprise-class sustainable software for the fuel industry in the US and Canada.",
  },
  {
    q: "Can you provide more information about SkyBitz?",
    a:
      "SkyBitz, a Telular AMETEK brand, delivers end-to-end asset management solutions for businesses. They help improve margins by automating workflow and human processes using intelligent data and devices.",
  },
  {
    q: "Can you provide more information about Mansfield Energy?",
    a:
      "Mansfield Energy provides innovative solutions for energy procurement, supply, and logistics challenges. They offer a broad range of solutions including fuels, natural gas, and energy price and data management services.",
  },
  {
    q: "What is the copyright year for KodeCorp?",
    a: "The copyright year for KodeCorp is 2023. All rights are reserved.",
  },
  {
    q: "What does PDI Technologies offer?",
    a:
      "PDI Technologies helps convenience retail and petroleum wholesale businesses increase efficiency and profitability by securely connecting their data and operations. They offer solutions for understanding consumer behavior and simplifying technology ecosystems across the supply chain.",
  },
  {
    q: "Can I manage my user profile on your website?",
    a: "Yes, you can manage your user profile on our website. Please log in to your account to access profile management features.",
  },
  {
    q: "What services does your website offer?",
    a:
      "Our website offers a range of services including procurement and settlement platforms, fuel clearing house automation, energy procurement solutions, asset management solutions, and technology development for web and mobile applications.",
  },
  {
    q: "Can you provide more information about your technology development services?",
    a:
      "Our technology development services include web and mobile application development, IoT solutions, and software development for corporate businesses. We have a team of highly skilled professionals who can help bring your technological ideas to life.",
  },
  {
    q: "What payment options do you accept?",
    a:
      "We accept various payment options, including credit cards, debit cards, and online payment platforms. For specific payment options and instructions, please refer to our website or contact our customer service team.",
  },
  {
    q: "What solutions do you provide for energy procurement?",
    a: "We provide innovative solutions for energy procurement, supply, and logistics challenges. Our solutions include fuels, natural gas, and energy price and data management services.",
  },
  {
    q: "What should I do if I have a complaint?",
    a: "If you have a complaint, please contact our customer service team. They will listen to your concerns and work towards resolving any issues you may have.",
  },
  {
    q: "Can you tell me more about your asset management solutions?",
    a:
      "Our asset management solutions help businesses improve margins by automating workflow and human processes using intelligent data and devices. We offer end-to-end solutions for managing assets and improving efficiency.",
  },
  {
    q: "Do you offer sustainable software solutions?",
    a:
      "Yes, we offer sustainable software solutions that are designed to be environmentally friendly and efficient. Our software solutions are developed with a focus on sustainability and long-term viability.",
  },
  {
    q: "Can I reschedule or cancel my appointment or reservation?",
    a:
      "For rescheduling or canceling appointments or reservations, please contact our customer service team. They will guide you through the process and assist you with any changes you need to make.",
  },
  {
    q: "How can I contact your customer service team?",
    a: "You can contact our customer service team by filling out the form on our website or by using the live chat feature. They will respond to your queries and provide assistance as needed..",
  },
  {
    q: "How can I request a refund?",
    a: "To request a refund, please contact our customer service team. They will guide you through the refund process and assist you with any refund-related queries or requests.",
  },
  {
    q: "Where are your store or service locations?",
    a: "Our store or service locations are primarily based in Hyderabad, Telangana, India. For specific addresses and directions, please contact our customer service team.",
  },
  {
    q: "How can I manage my subscription?",
    a: "To manage your subscription, please contact our friendly customer service team. They will assist you with any subscription-related queries or requests.",
  },
  {
    q: "How can I confirm my appointment or visit?",
    a: "To confirm your appointment or visit, please contact our customer service team. They will provide you with the necessary information and assist you in confirming your appointment.",
  },
  {
    q: "What is the address of your corporate office?",
    a: "Our corporate office is located at Plot No.265, Road No. 6 Kakatiya hills, Madhapur, Hyderabad, Telangana, India 500081.",
  },
  {
    q: "How can I contact your team for service requests?",
    a: "To contact our team for service requests, please fill out the form on our website with detailed information about your request. Our team will respond to you as soon as possible.",
  },
  {
    q: "Do you offer any discounts?",
    a: "We occasionally offer discounts on our services. For information about current discounts or promotions, please visit our website or contact our customer service team.",
  },
];

function Chatbot() {
  let keyword = [
    "hello",
    "Hi",
    "Mansfield",
    "Energy",
    "business",
    "SkyBitz",
    "information",
    "settlement",
    "services",
    "kodecorp",
    "Veri-Fuel",
    "platforms",
    "procurement",
    "fuel",
    "automation",
    "copyright",
    "PDI",
    "profile",
    "services",
    "technology",
    "development",
    "payment",
    "complaint",
    "asset",
    "sustainable",
    "software",
    "reschedule",
    "appointment",
    "reservation",
    "contact",
    "customer service ",
    "refund",
    "request",
    "store",
    "locations",
    "subscription",
    "visit",
    "confirm",
    "address",
    "office",
    "team",
    "discounts",
  ];

  const result = (value) => {
    let inputArray = [];
    value.previousValue.split(" ").forEach((character) => {
      inputArray.push(character);
    });

    const len = inputArray.length;
    let count = 0;

    let res = "";
    inputArray.map((element) => {
      if (keyword.find((item) => item.toLowerCase().startsWith(element.toLowerCase()))) {
        if (element) {
          const search = data.filter((item) => item.q.toLowerCase().includes(element.toLowerCase()))[0];
          res = search.a;
          return res;
        }
      } else {
        count += 1;

        res = "I apologize, but I do not have enough information to answer your question.";
        return res;
      }

      if (count === len) {
        return res;
      } else {
        return res;
      }
    });

    return res;
  };

  const validate = (value) => {
    console.log(value);
    if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(value.previousValue)) {
      return ` hi ${value.previousValue.substring(0, value.previousValue.lastIndexOf("@"))} 👋,how can I help you?`;
    } else {
      return ` hi ${value.previousValue} 👋, how can I help you?`;
    }
  };

  // const satisfied=(valid)=>{
  //   console.log(valid)
  // if(!valid){
  //   console.log(valid)
  //   return "emailInput1"
  // }else{
  //   console.log(valid)
  //   return "waiting3"
  // }
  // }

  const steps = [
    {
      id: "greet",
      message: "Hello 👋 Would you like to take a closer look at our offer?",
      trigger: "ask name",
    },
    {
      id: "ask name",
      options: [
        { value: "yes", label: "Yes,please!👍", trigger: "update-yes" },
        { value: "no", label: "No,thanks👎.", trigger: "update-no" },
      ],
    },
    {
      id: "update-no",
      message: "All right! We'll contact you as soon as we can 🤗.",
      trigger: "waiting3",
    },
    {
      id: "update-yes",
      message: "Great!🙌 In that case, could you provide your phone number or e-mail address so that we can get in touch with you?",
      trigger: "ask details",
    },
    {
      id: "ask details",
      options: [
        { value: "email", label: "E-mail📧", trigger: "email" },
        { value: "phonenumber", label: "Phone Number📞", trigger: "phonenumber" },
      ],
    },

    {
      id: "email",
      message: "Sure! What's your e-mail address?",
      trigger: "emailInput",
    },
    {
      id: "phonenumber",
      message: "Sure! Enter your phone number!",
      trigger: "phone",
    },
    {
      id: "phone",
      user: true,
      trigger: "userInput",
    },

    {
      id: "emailInput",
      user: true,
      trigger: "userInput",
    },
    {
      id: "userInput",
      message: validate,
      trigger: "waiting3",
    },
    {
      id: "waiting3",
      user: true,
      trigger: "waiting4",
    },
    {
      id: "waiting4",
      message: result,
      trigger: "waiting3",
    },
  ];

  const config = {
    floating: true,
  };

  const theme = {
    background: "white",
    headerBgColor: "rgb(90, 177, 253)",
    headerFontSize: "22px",
    botBubbleColor: "rgb(240, 242, 247)",
    headerFontColor: "white",
    botFontColor: "rgb(6, 19, 43)",
    userBubbleColor: "linear-gradient(135deg, rgb(90, 177, 253), rgb(90, 177, 253))",
    userFontColor: "#ffffff",
  };

  return (
    <div className="App">
      <header className="App-header">
        <ThemeProvider theme={theme}>
          <ChatBot {...config} steps={steps} headerTitle="KodeCorp" />
        </ThemeProvider>
      </header>
    </div>
  );
}

export default Chatbot;
