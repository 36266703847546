import React, { useEffect } from "react";
import logo from "../assets/img/KodeCorp-logo2.png";
import { useLocation, useNavigate } from "react-router-dom";
import line from "../assets/img/selectLine.png";
import Dropdown from "react-bootstrap/Dropdown";
import { DropdownButton } from "react-bootstrap";
const data = [
  { Name: "Home", id: 0, path: "/" },
  { Name: "About Us", id: 1, path: "/about" },
  {
    Name: "Services",
    id: 2,
    path: "/services",
    subMenu: [
      { Subid: 4, text: "Enterprise Software Development", path: "/enterprise-software-development-company" },
      { Subid: 5, text: "Mobile App Development", path: "/mobile-app-development-company" },
      { Subid: 6, text: "Data Science Services", path: "/data-science-consulting-services" },
      { Subid: 7, text: "Generative AI Consulting", path: "/generative-ai-consulting-company" },
      { Subid: 8, text: "DevOps Services", path: "/devops-consulting-services" },
      { Subid: 9, text: "Cloud Consulting Services", path: "/cloud-consulting-services" },
    ],
  },
  { Name: "Blog", id: 3 },
  { Name: "Contact Us", id: 4, path: "/contact" },
];
const Header = ({ onChange }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [value, setvalue] = React.useState(data[1].id);
  const [initialService, setIntitalService] = React.useState(data[2].subMenu[0].Subid);

  // useEffect(() => {
  //   navigate(`/service${initialService - 3}`)
  //   // setIntitalService(4)
  // }, [initialService])

  useEffect(() => {
    if (data[2].subMenu.map((x) => x.path).includes(location.pathname)) {
      setvalue(2);
    } else {
      if (data.filter((x) => x.path === location.pathname).length !== 0) {
        setvalue(data.filter((x) => x.path === location.pathname)[0].id);
      } else {
        setvalue(1);
      }
    }
  }, []);

  // const changePageContent = (id) => {
  //   if (location.pathname !== "/serviceSubPage") {
  //     navigate("/serviceSubPage", { state: { id: id } });
  //   } else {
  //     onChange(id)
  //   }
  // };
  return (
    <div className="main-header">
      <div className="header">
        <img src={logo} className="image cursorpointer" alt="header-logo" onClick={() => navigate("/")} />
        <div className="menu-items">
          {data.map((each, idx) => {
            return (
              <div key={each.id} className="d-flex flex-column" style={{ lineHeight: "0" }}>
                {each.id === 2 ? (
                  <DropdownButton
                    variant=""
                    id="dropdown-basic"
                    title="Services"
                    onClick={() => {
                      // navigate(`/service${initialService - 3}`)
                      setvalue(2);
                      setIntitalService(4);
                    }}
                  >
                    {each.subMenu.map((each, idx) => (
                      <Dropdown.Item
                        key={idx}
                        // active={idx + 4 === initialService}
                        onClick={() => {
                          navigate(each.path);
                          setIntitalService(each.Subid);
                        }}
                      >
                        {each.text}
                      </Dropdown.Item>
                    ))}
                  </DropdownButton>
                ) : each.id === 3 ? (
                  <a className={value === idx ? "selectItem button-item" : "button-item"} target="_blank" href="https://kodecorp.com/blog/home.html">
                    {each.Name}
                  </a>
                ) : (
                  <span
                    className={value === idx ? "selectItem button-item" : "button-item"}
                    onClick={() => {
                      setvalue(each.id);
                      navigate(each.path);
                    }}
                  >
                    {each.Name}
                  </span>
                )}

                {value === idx && <img src={line} alt="line" className="line" />}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Header;
