import React from "react";
import "../styles/style.css";
import footerimg from "../assets/img/footerimg.png";
import blackHyd from "../assets/img/FooterHYDimg.png";
import { AiFillLinkedin } from "react-icons/ai";
import { Link, useLocation, useNavigate } from "react-router-dom";

const serviceData = [
  { id: 1, text: "Enterprise Software Development", path: "/enterprise-software-development-company" },
  { id: 2, text: "Mobile App Development", path: "/mobile-app-development-company" },
  { id: 3, text: "Data Science Services", path: "/data-science-consulting-services" },
  { id: 4, text: "Generative AI Consulting", path: "/generative-ai-consulting-company" },
  { id: 5, text: "DevOps Services", path: "/devops-consulting-services" },
  { id: 6, text: "Cloud Consulting Services", path: "/cloud-consulting-services" },
];
const Footer = ({ show, onChange }) => {
  const navigate = useNavigate();
  const location = useLocation();

  // const changePageContent = (id) => {
  //   if (location.pathname !== "/serviceSubPage") {
  //     navigate("/serviceSubPage", { state: { id: id } });
  //   } else {
  //   }
  // };

  return (
    <div className={show ? "" : "main-section setFooter"}>
      <footer className="text-sm-start text-lg-start text-white total" style={{ backgroundColor: show ? "#F8F8F8" : "#C23B23" }}>
        <div className="container p-4 pb-0">
          <div className="row">
            <div className="col-6 col-md-6 col-lg-2 mb-4 mb-md-0">
              <h5 className={show ? "footer-text changeText" : "footer-text"}>KodeCorp</h5>

              <ul className="list-unstyled mb-0">
                <li>
                  <Link to="/about" className={show ? "listText" : "listText list"}>
                    About Us
                  </Link>
                </li>
                <li>
                  <Link to="/enterprise-software-development-company" className={show ? "listText" : "listText list"}>
                    Services
                  </Link>
                </li>
                <li>
                  <Link to="https://kodecorp.com/blog/home.html" target="_blank" rel="noopener noreferrer" className={show ? "listText" : "listText list"}>
                    Blog
                  </Link>
                </li>
                <li>
                  <Link to="/contact" className={show ? "listText" : "listText list"}>
                    Contact Us
                  </Link>
                </li>
              </ul>
            </div>

            <div className="col-6 col-md-6 col-lg-3 mb-4 mb-md-0">
              <h5 className={show ? "footer-text changeText" : "footer-text"}>Services</h5>

              <ul className="list-unstyled mb-0">
                {serviceData.map((each, idx) => (
                  <li key={idx}>
                    <span className={show ? "listText" : "listText list"} onClick={() => navigate(each.path)} style={{ cursor: "pointer" }}>
                      {each.text}
                    </span>
                  </li>
                ))}
              </ul>
            </div>

            <div className="col-12 col-md-12 col-lg-7 mb-4 mb-md-0">
              <h5 className={show ? "footer-text changeText" : "footer-text"}>Contact</h5>
              {/* <div className="contact-info col-12">
                                <div className="footer-sub-img-bg " style={{ marginTop: "15px" }}>
                                    <img src={show ? blackUsa : footerimg2} className={show ? "US-img img-US" : "US-img"} style={{ marginRight: "20px" }} alt="foot" />
                                    <div className="col-lg-6 ">
                                        <h6 className={show ? "contact-bg-heading m-2 changeHead" : "contact-bg-heading m-2"}>US Charlotte</h6>
                                        <p className={show ? "contact-bg-paragraph m-2 changePara" : "contact-bg-paragraph m-2"}>US - Charlotte 10308 Barberville Rd, Fort Mill, South Carolina USA 29708</p>
                                    </div>
                                </div>
                                <div style={{ marginTop: "15px" }}>
                                    <h6 className={show ? "contact-bg-heading m-2 changeHead" : "contact-bg-heading m-2"}>US-Bay Area</h6>
                                    <p className={show ? "contact-bg-paragraph m-2 changePara" : "contact-bg-paragraph m-2"}>US - Bay Area2 Pierce Ave, San Jose, California USA 95125</p>
                                </div>
                            </div> */}
              {/* <hr style={{ color: show ? "#757575" : "white", opacity: "1" }}></hr> */}
              {/* /////////////////////// second-part */}
              <div className="contact-info col-12">
                <div className="footer-sub-img-bg " style={{ marginTop: "15px" }}>
                  <img src={show ? blackHyd : footerimg} className="US-img" style={{ marginRight: "20px" }} alt="footer" />
                  <div className="col-sm-12 col-lg-12 ">
                    <h6 className={show ? "contact-bg-heading m-2 changeHead" : "contact-bg-heading m-2"}>India Corporate Office</h6>
                    <p className={show ? "contact-bg-paragraph m-2 changePara" : "contact-bg-paragraph m-2"}>Hyderabad - Corporate Office Plot No.265, Road No . 6 Kakatiya hills, Madhapur, Hyderabad, Telangana India 500081</p>
                  </div>
                </div>
                <div className="col-sm-12 col-lg-12" style={{ marginTop: "15px" }}>
                  <h6 className={show ? "contact-bg-heading m-2 changeHead" : "contact-bg-heading m-2"}>India Tech Center</h6>
                  <p className={show ? "contact-bg-paragraph m-2 changePara" : "contact-bg-paragraph m-2"}>
                    Hyderabad <br></br> Tech Center Purva Summit, 4th Floor, Whitefields, HITEC City Hyderabad, Telangana India 500081
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="bg-icon mt-2  mb-4 mb-md-0 ">
            <h5 className={show ? "footer-socialmedia-text changeText" : "footer-socialmedia-text"}>Follow us</h5>
            <div className={show ? "footer-social-icons" : "icons"}>
              {/* <FaTwitterSquare className="footer-icon" />
                            <FaFacebookSquare className="footer-icon" />
                            <FaYoutubeSquare className="footer-icon" />
                            <FaInstagramSquare className="footer-icon" /> */}
              <Link to="https://www.linkedin.com/company/kodecorp/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
                <AiFillLinkedin className={show ? "footer-icon footer-social-icons" : "footer-icon"} />
              </Link>
            </div>
          </div>
        </div>
        <div className="text-center p-3" style={{ backgroundColor: "#579ABE", color: "#fffff", opacity: "1", marginTop: "25px" }}>
          © Copyright 2024. All Rights Reserved. KODECORP
        </div>
      </footer>
    </div>
  );
};

export default Footer;
