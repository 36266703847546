import React from "react";
import upImage from "../../assets/img/upimage.png";
import downImage from "../../assets/img/downImage.png";
import { useNavigate } from "react-router-dom";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Cardimg31 from "../../assets/img/Cardimg31.png";
import Cardimg32 from "../../assets/img/Cardimg32.png";
import Cardimg33 from "../../assets/img/Cardimg33.png";
import Cardimg34 from "../../assets/img/Cardimg34.png";
import Cardimg35 from "../../assets/img/Cardimg35.png";
import Cardimg36 from "../../assets/img/Cardimg36.png";
import Cardimg312 from "../../assets/img/Cardimg312.png";
import Cardimg313 from "../../assets/img/Cardimg313.png";
import Cardimg314 from "../../assets/img/Cardimg314.png";
import Cardimg315 from "../../assets/img/Cardimg315.png";
import Cardimg316 from "../../assets/img/Cardimg316.png";
import Cardimg317 from "../../assets/img/Cardimg317.png";
import android from "../../assets/img/android.png";
import ios from "../../assets/img/iOS.png";
import kotlin from "../../assets/img/kotlin.png";
import swift from "../../assets/img/swift.png";
import react from "../../assets/img/react.png";
import flutter from "../../assets/img/flutter.png";
import shopify from "../../assets/img/shopify.png";
import aws from "../../assets/img/aws.png";
import azure from "../../assets/img/azure.png";
import gcloud from "../../assets/img/gcloud.png";
import docker from "../../assets/img/docker.png";
import kubernetes from "../../assets/img/kubernetes.png";
import cicd from "../../assets/img/cicd.png";
import mongodb from "../../assets/img/mongodb.png";
import postgresql from "../../assets/img/postgresql.png";
import dynamodb from "../../assets/img/dynamodb.png";
import mysql from "../../assets/img/mysql.png";
import mariadb from "../../assets/img/mariadb.png";

const techstack1 = [
  {
    id: 1,
    img: Cardimg31,
    label: <p className="mt-2">HTML</p>,
  },
  {
    id: 2,
    img: Cardimg32,
    label: <p className="mt-2">CSS</p>,
  },
  {
    id: 3,
    img: Cardimg33,
    label: <p className="mt-2">Bootstrap</p>,
  },
  {
    id: 4,
    img: Cardimg34,
    label: <p className="mt-2">Javascript</p>,
  },
  {
    id: 5,
    img: Cardimg35,
    label: <p className="mt-2">jQuery</p>,
  },
  {
    id: 6,
    img: react,
    label: <p className="mt-2">React JS</p>,
  },
];
const techstack2 = [
  {
    id: 1,
    img: Cardimg312,
    label: <p className="mt-2">Java</p>,
  },
  {
    id: 2,
    img: Cardimg313,
    label: <p className="mt-2">ASP.NET Core</p>,
  },
  {
    id: 3,
    img: Cardimg314,
    label: <p className="mt-2">.Net</p>,
  },
  {
    id: 4,
    img: Cardimg315,
    label: <p className="mt-2">ASP .Net</p>,
  },
  {
    id: 5,
    img: Cardimg316,
    label: <p className="mt-2">C#</p>,
  },
  {
    id: 6,
    img: Cardimg317,
    label: <p className="mt-2">Node .Js</p>,
  },
];

const techstack3 = [
  {
    id: 1,
    img: android,
    label: <p className="mt-2">Android</p>,
  },
  {
    id: 2,
    img: ios,
    label: <p className="mt-2">iOS</p>,
  },
  {
    id: 3,
    img: kotlin,
    label: <p className="mt-2">Kotlin</p>,
  },
  {
    id: 4,
    img: swift,
    label: <p className="mt-2">Swift</p>,
  },
  {
    id: 5,
    img: react,
    label: <p className="mt-2">React Native</p>,
  },
  {
    id: 6,
    img: flutter,
    label: <p className="mt-2">Flutter</p>,
  },
];

const techstack4 = [
  {
    id: 1,
    img: shopify,
    label: <p className="mt-2">shopify</p>,
  },
];

const techstack5 = [
  {
    id: 1,
    img: aws,
    label: <p className="mt-2">AWS</p>,
  },
  {
    id: 2,
    img: azure,
    label: <p className="mt-2">Azure</p>,
  },
  {
    id: 3,
    img: gcloud,
    label: <p className="mt-2">Google Cloud</p>,
  },
  {
    id: 4,
    img: docker,
    label: <p className="mt-2">Docker</p>,
  },
  {
    id: 5,
    img: kubernetes,
    label: <p className="mt-2">Kubernetes</p>,
  },
  {
    id: 6,
    img: cicd,
    label: <p className="mt-2">CI/CD</p>,
  },
];

const techstack6 = [
  {
    id: 1,
    img: mongodb,
    label: <p className="mt-2">Mongodb</p>,
  },
  {
    id: 2,
    img: postgresql,
    label: <p className="mt-2">PostgreSQL</p>,
  },
  {
    id: 3,
    img: dynamodb,
    label: <p className="mt-2">DynamoDB</p>,
  },
  {
    id: 4,
    img: mysql,
    label: <p className="mt-2">MySQL</p>,
  },
  {
    id: 5,
    img: mariadb,
    label: <p className="mt-2">MariaDB</p>,
  },
  {
    id: 6,
    img: flutter,
    label: <p className="mt-2">Flutter</p>,
  },
];

const Section7 = () => {
  const navigate = useNavigate();
  return (
    <section className="main-section sub-section  bg-container7 position-relative" id="section1">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img flex-sm-{grow|shrink}-1" src={upImage} alt="up" />
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={downImage} alt="down" />
      </div>
      <div className="content-card card-1 col-12 techstackTabs">
        <div className="main-text-cont">
          <h1 className="heading2">
            <span className="frame1">
              <span>Our Tech Stack: Powering Large-Scale Success</span>
              {/* <span>Platforms </span>
              <span>For </span> */}
            </span>
            {/* <span className="frame2">
              <span>Your </span>
              <span>Products</span>
            </span> */}
          </h1>
          <p className="sub-head">Our tech stack powers high-scale products, achieving millions of transactions yearly, reflecting our dedication to impactful, expansive solutions.</p>

          {/* <h1 className="heading">
            <div class="drop-main">
              <div className="c">C</div>
              <div className="r">r</div>
              <div className="e">e</div>
              <div className="d">d</div>
              <div className="i">i</div>
              <div className="b">b</div>
              <div className="l">l</div>
              <div className="e1 me-4">e</div>

              <div className="p0">P</div>
              <div className="l1">l</div>
              <div className="a">a</div>
              <div className="t">t</div>
              <div className="f">f</div>
              <div className="o">o</div>
              <div className="r1">r</div>
              <div className="m">m</div>
              <div className="s me-4">s</div>

              <div className="f1">F</div>
              <div className="o1">o</div>
              <div className="r2">r</div>
            </div>
            <div class="drop-main">
              <div className="y">Y</div>
              <div className="o2">o</div>
              <div className="u">u</div>
              <div className="r3 me-4">r</div>

              <div className="p1">P</div>
              <div className="r4">r</div>
              <div className="o3">o</div>
              <div className="d1">d</div>
              <div className="u1">u</div>
              <div className="c1">c</div>
              <div className="t1">t</div>
              <div className="s1">s</div>
            </div>
          </h1> */}

          {/* <div className="slider mt-4">
            <div className="slide-track">
              <div className="slide-text">HTML</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">CSS</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Bootstrap</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">JavaScript</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">jQuery</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Semantic UI</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">React</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Angular</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Node.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Next.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>

              <div className="slide-text">HTML</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">CSS</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Bootstrap</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">JavaScript</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">jQuery</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Semantic UI</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">React</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Angular</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Node.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Next.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div>

          <div className="slider mt-3">
            <div className="slide-track2">
              <div className="slide-text">Java</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">ASP.NET Core</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">.Net</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">ASP .Net</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">C#</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Node.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Python</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Ruby</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">PHP</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">COBOL</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Next.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Nest.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Java</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">ASP.NET Core</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">.Net</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">ASP .Net</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">C#</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Node.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Python</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Ruby</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">PHP</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">COBOL</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Next.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
              <div className="slide-text">Nest.Js</div>
              <div className="d-flex align-items-center">
                <i className="fa fa-circle" aria-hidden="true"></i>
              </div>
            </div>
          </div> */}

          <Tabs defaultActiveKey="frontend" id="uncontrolled-tab-example" className="mb-3 w-100">
            <Tab eventKey="frontend" title="Frontend">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack1.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="backend" title="Backend">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack2.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="mobileapp" title="Mobile App">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack3.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="opensource" title="Open Source">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack4.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="cloud&devops" title="Cloud & DevOps">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack5.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
            <Tab eventKey="database" title="Database">
              <div className="d-flex flex-row flex-wrap justify-content-center">
                {techstack6.map((each) => {
                  return (
                    <div className="p-2 col-lg-2 col-md-3 col-sm-6 col-xs-6 text-center">
                      <div className="bg-card3 mb-3">
                        <img className="stackimgs" src={each.img} alt="card1" />
                      </div>
                      {each.label}
                    </div>
                  );
                })}
              </div>
            </Tab>
          </Tabs>

          <span className="link">
            <span>View All Technologies</span>
          </span>
        </div>
      </div>
    </section>
  );
};

export default Section7;
