import React from "react";
import pagedown3 from "../../assets/img/pagedown2.png";
import pageup3 from "../../assets/img/pageup2.png";
import CarouselItem from "./../carousels/aboutcarousel";
import { HiChevronDoubleRight } from "react-icons/hi";
import group from "../../assets/img/Groupimg.png";
import { useNavigate } from "react-router-dom";

const Section3 = () => {
  const navigate = useNavigate();
  return (
    <section className="main-section sub-section  bg-container2  position-relative" id="section3">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={pagedown3} alt="up" />
        <img className="sec-one-img align-self-start flex-sm-{grow|shrink}-1" src={pageup3} alt="down" />
      </div>
      <div className="content-card card-3 col-12">
        <div className="about-content text-white deliever-content col-12 col-md-8 col-lg-5">
          <p>Who We Are</p>
          <h1 className="about-head">
            Embark On A Journey With <span className="kode kode-2">Kodecorp</span>{" "}
          </h1>
          <p className="about-para">
            Where Every Challenge Is Met With An Innovative Solution, Crafted With Passion And Precision. Your vision is our canvas, and our passion for technology is the brush. At KodeCorp, we don't just build digital solutions; we craft digital legacies. Join us in this journey of innovation and
            let's sculpt the future together.
          </p>
          <button className="explore-btn btn_sec_3 px-4" onClick={() => navigate("/contact")}>
            <span>Explore More</span>{" "}
            <span className="btn_arrow">
              <HiChevronDoubleRight />
            </span>
          </button>
        </div>
        <div className="col-10 col-md-8 col-lg-4 d-flex justify-content-center align-items-center">
          <img src={group} alt="service" className="service-img " />
        </div>
      </div>
    </section>
  );
};

export default Section3;
