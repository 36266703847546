import Slider from "react-slick";
// import select from "../../assets/img/select.png"
// import phone from "../../assets/img/smartphone.png"
// import sheid from "../../assets/img/shield.png"
// import users from "../../assets/img/users.png"
// import web from "../../assets/img/window.png"
import mansfield from "../../assets/img/Mansfield.png"
import verifuel from "../../assets/img/verifuel.png"
import skybitz from "../../assets/img/Skybitz.png"
import PDI from "../../assets/img/PDI.png"
import FCH from "../../assets/img/fch.png"
import { HiArrowNarrowRight, HiArrowNarrowLeft } from "react-icons/hi"
import { MdKeyboardDoubleArrowLeft, MdKeyboardDoubleArrowRight } from "react-icons/md"
import display from "../../assets/img/section4Display.png"
import link from "../../assets/img/section4link.png"
import sim from "../../assets/img/section4sim-card.png"
import folder from "../../assets/img/section4folder-open.png"
import ai from "../../assets/img/section4artificial-intelligence.png"
import cloud from "../../assets/img/section4cloud-download.png"
import React from "react"

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import GallaryTabs from "../Tabstrip/tabs";
const ServiceCarousel = () => {
    const testimonials = [
        {
            id: 1,
            name: "Enterprise Software Development",
            image: display

        },
        {
            id: 2,
            name: "Full Team Services",
            image: link
        },
        {
            id: 3,
            name: "Mobile App Development",
            image: sim

        },
        {
            id: 4,
            name: "Data Science",
            image: folder
        },
        {
            id: 5,
            name: "Generative AI",
            image: ai

        },
        {
            id: 6,
            name: "Devops And Cloud",
            image: cloud

        }
    ];
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (
            <HiArrowNarrowRight className="section4_arrows slick-right " onClick={onClick} />
        );
    }
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <HiArrowNarrowLeft className="section4_arrows slick-left" onClick={onClick} />
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1500,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1366,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    // infinite: true,
                    // dots: true
                }
            },
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="testimonial client-testimonial my-4">
            <Slider {...settings} >
                {testimonials.map((testimonial, idx) => (
                    <div className="testimionals" key={idx}>
                        <div className="mx-2 testimonial-item ">
                            <img src={testimonial.image} alt={testimonial.name} width="48" />
                            <h5 style={{ color: "black", textAlign: "center" }}>
                                {testimonial.name} <span className="text-primary"> {testimonial.designation}</span>
                            </h5>
                        </div>
                    </div>
                ))}
            </Slider>
        </div>
    )
}
const ClientCarousel = () => {
    const testimonials = [
        {
            id: 1,
            name: "Super Helpful!!!!",
            sub: "Mansfield Energy provides innovative solutions to the most demanding energy procurement, supply and logistics challenges for organizations across North America. Mansfield offer's a broad range of solutions including fuels, natural gas and energy price and data management services.",
            image: mansfield,
            company: "Mansfield"

        },
        {
            id: 2,
            name: "Awesome !!!!",
            sub: "Veri-Fuel is transforming the fuel industry in the US and Canada by providing end to end enterprise class sustainable software to over 1500 companies. Some of the most awaited and ambitious products are currently in development and soon to be launched! We are proud to be Veri-Fuel's trusted technology partner",
            image: verifuel,
            company: "Veri-Fuel"
        }
        ,
        {
            id: 3,
            name: "Super Helpful!!!!",
            sub: "SkyBitz, a Telular AMETEK brand, delivers end-to-end asset management solutions for businesses seeking to improve margins by automating workflow and human processes using intelligent data and devices. KodeCorp has been a trusted technology partner for Skybitz since 2013 providing services in IOT, Web and Mobile applications.",
            image: skybitz,
            company: "Skybitz"

        },
        {
            id: 4,
            name: "Awesome !!!!",
            sub: <p>KodeCorp was contracted to augment our engineering capabilities at PDI Technologies team as we accelerated a <span style={{fontWeight:"bold",backgroundColor:"yellow"}}>large strategic project with a modern, multi-browser, compatible user interface</span>. Over the last year, they’ve demonstrated excellent collaboration by delivering on commitments, worked with our teams to ensure <span style={{fontWeight:"bold",backgroundColor:"yellow"}}>efficient handoffs, responded positively to feedback, provided suggestions</span> on how we can improve our own internal process, and more. We are excited to continue the relationship and together deliver additional milestones for this initiative. </p>,
            image: PDI,
            company: "PDI Technologies"
        },
        {
            id: 3,
            name: "Super Helpful!!!!",
            sub: "Fuel Clearing House automates the accounts payable process, thus accelerating the fuel procurement cycle. The only Clearing House for Fuel Transactions that will reduce costly matching, auditing, reconciliation, and credit and re-bill headaches. All this is done electronically before an e-Invoice is even presented to your A/P Department for Payment.",
            image: FCH,
            company: "Fuel Clearing House"


        },
    ]
    function SampleNextArrow(props) {
        return (
            <HiArrowNarrowRight className="slickArrow slick-right " />
        );
    }
    function SamplePrevArrow(props) {
        return (
            <HiArrowNarrowLeft className="slickArrow slick-left" />
        );
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        speed: 2000,
        autoplaySpeed: 1500,
        cssEase: "linear",
        arrows: false,
        // nextArrow: <SampleNextArrow />,
        // prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div className="client-testimonial1 mt-3">
            <Slider {...settings} >
                {testimonials.map((testimonial, idx) => (
                    <div className="cilent-carousel" key={idx}>
                        <div className="client m-2">
                            {/* <h3 className="text-center fw-bold card-head" style={{ color: "black" }}>
                                {testimonial.name}
                            </h3> */}
                            <p className="text-center card-para" style={{ width: "90%" }}>{testimonial.sub}</p>
                            <div className="d-flex justify-content-center align-items-center gap-2">

                                <div style={{ height: "69px", width: "69px", borderRadius: "50%", border: "1px solid #EEEEEE" }} className="d-flex justify-content-center align-items-center">
                                    <img src={testimonial.image} alt={testimonial.company} style={{ width: "80%" }} />
                                </div>
                                <h3 className="text-center fw-bold client-head" style={{ color: "black" }}>
                                    {testimonial.company}
                                </h3>
                            </div>
                        </div>
                        {/* <div className="client1 ">
                            <div style={{ height: "69px", width: "69px", borderRadius: "50%", border: "1px solid #EEEEEE" }} className="d-flex justify-content-center align-items-center">
                                <img src={testimonial.image} alt={testimonial.company} style={{ width: "80%" }} />
                            </div>
                            <h3 className="text-center fw-bold client-head" style={{ color: "black" }}>
                                {testimonial.company}
                            </h3>
                        </div> */}
                    </div>
                ))}
            </Slider>
        </div>
    )
}

const TabCarousel = ({ onChangePic }) => {
    const Events = [{ id: 1, event: `10th Year Anniversary Celebrations` }, { id: 3, event: "Dussehra Celebrations" }, { id: 2, event: "Diwali Celebration" }, { id: 4, event: "Ganapathi Celebrations" }]
    const [selectEvent, setSelectEvent] = React.useState(Events[0].id)
    const onChange = (id) => {

        setSelectEvent(id)
        onChangePic(id)

    }
    function SampleNextArrow(props) {
        const { onClick } = props;
        return (

            <MdKeyboardDoubleArrowRight className="slickArrow slick-right gallary" onClick={onClick} />
        );
    }
    function SamplePrevArrow(props) {
        const { onClick } = props;
        return (
            <MdKeyboardDoubleArrowLeft className="slickArrow slick-left gallary" onClick={onClick} />

        );
    }
    const settings = {
        dots: false,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        // autoplay: false,
        // speed: 2000,
        // autoplaySpeed: 5000,
        cssEase: "linear",
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 996,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div style={{ width: "100%" }} className="m-2">
            <Slider {...settings} >

                {Events.map((x, idx) => {
                    return (

                        <div className="m-2 w-100" key={idx}>

                            <button key={idx} className={selectEvent === x.id ? "event-btn" : "event-btn btn-event"} onClick={() => onChange(x.id)}>{x.event}</button>
                        </div>

                    )
                })}

            </Slider>

        </div>
    )
}
export { ServiceCarousel, ClientCarousel, TabCarousel }