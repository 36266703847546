import React from "react";
import { useEffect, useState } from "react";
import Section7 from "./section7";
import Section1 from "./section1";
import Section2 from "./section2";
import Section5 from "./section5";
import Section6 from "./section6";
import Section3 from "./section3";
import Section4 from "./section4";
import Footer from "../footer";
import logo from "../../assets/img/kodecorplogo.png";
import menu from "../../assets/img/rectangle-list.png";
import scrolldown from "../../assets/img/scrolldown.png";
import scrollUp from "../../assets/img/scrollup.png";
import anni4 from "../../assets/img/anni.svg";
import { Link } from "react-router-dom";
import { SlClose } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const pageTabs = [{ section: 0 }, { section: 1 }, { section: 2 }, { section: 3 }, { section: 4 }, { section: 5 }, { section: 6 }];
  const [selectiontab, setSelectiontab] = useState(pageTabs[0].section);

  const navigate = useNavigate();
  useEffect(() => {
    let sections = document.querySelectorAll("section");
    window.onscroll = () => {
      sections.forEach((x, idx) => {
        let top = window.scrollY;
        let offset = x.offsetTop;
        let height = x.offsetHeight;
        let element = document.getElementById(`page${idx}`);
        if (top + 1 >= offset && top + 1 < offset + height) {
          setSelectiontab(idx);
          element.classList.add("active");
        } else {
          if (element === null) return;
          element.classList.remove("active");
        }
      });
    };
  }, []);

  function scrollFunction() {
    let selectedtab = selectiontab + 1;
    if (selectedtab === 7) {
      window.location.href = `#section0`;
    } else {
      window.location.href = `#section${selectedtab}`;
    }
  }
  function changePage(num) {
    console.log(num);
    window.location.href = `#section${num}`;
  }
  return (
    <div>
      <div className="main-cont ">
        <div className="nav-cont"></div>
        <div className={!openMenu ? "menu" : "menu openMenu"}>
          <SlClose className="closeMenu" onClick={() => setOpenMenu(false)} />
          {openMenu && (
            <nav>
              <ul>
                <li>
                  <Link>Home</Link>
                </li>
                <li>
                  <Link to="/about">About us</Link>
                </li>
                <li>
                  <Link to="/enterprise-software-development-company">Services</Link>
                </li>
                <li>
                  <a target="_blank" href="https://kodecorp.com/blog/home.html">
                    Blog
                  </a>
                </li>
                <li>
                  <Link to="/contact">Contact us</Link>
                </li>
              </ul>
            </nav>
          )}
        </div>
        <nav className="navbar-cont">
          <div className="position-relative">
            {/* <div className="wrapper1">
              <div class="confetti-149"></div>
              <div className="confetti-148"></div>
              <div className="confetti-147"></div>
              <div className="confetti-146"></div>
              <div className="confetti-145"></div>
              <div className="confetti-144"></div>
              <div className="confetti-143"></div>
              <div className="confetti-142"></div>
              <div className="confetti-141"></div>
              <div className="confetti-140"></div>
              <div className="confetti-139"></div>
              <div className="confetti-138"></div>
              <div className="confetti-137"></div>
              <div className="confetti-136"></div>
              <div className="confetti-135"></div>
              <div className="confetti-134"></div>
              <div className="confetti-133"></div>
              <div className="confetti-132"></div>
              <div className="confetti-131"></div>
              <div className="confetti-130"></div>
              <div className="confetti-129"></div>
              <div className="confetti-128"></div>
              <div className="confetti-127"></div>
              <div className="confetti-126"></div>
              <div className="confetti-125"></div>
              <div className="confetti-124"></div>
              <div className="confetti-123"></div>
              <div className="confetti-122"></div>
              <div className="confetti-121"></div>
              <div className="confetti-120"></div>
              <div className="confetti-119"></div>
              <div className="confetti-118"></div>
              <div className="confetti-117"></div>
              <div className="confetti-116"></div>
              <div className="confetti-115"></div>
              <div className="confetti-114"></div>
              <div className="confetti-113"></div>
              <div className="confetti-112"></div>
              <div className="confetti-111"></div>
              <div className="confetti-110"></div>
              <div className="confetti-109"></div>
              <div className="confetti-108"></div>
              <div className="confetti-107"></div>
              <div className="confetti-106"></div>
              <div className="confetti-105"></div>
              <div className="confetti-104"></div>
              <div className="confetti-103"></div>
              <div className="confetti-102"></div>
              <div className="confetti-101"></div>
              <div className="confetti-100"></div>
              <div className="confetti-99"></div>
              <div className="confetti-98"></div>
              <div className="confetti-97"></div>
              <div className="confetti-96"></div>
              <div className="confetti-95"></div>
              <div className="confetti-94"></div>
              <div className="confetti-93"></div>
              <div className="confetti-92"></div>
              <div className="confetti-91"></div>
              <div className="confetti-90"></div>
              <div className="confetti-89"></div>
              <div className="confetti-88"></div>
              <div className="confetti-87"></div>
              <div className="confetti-86"></div>
              <div className="confetti-85"></div>
              <div className="confetti-84"></div>
              <div className="confetti-83"></div>
              <div className="confetti-82"></div>
              <div className="confetti-81"></div>
              <div className="confetti-80"></div>
              <div className="confetti-79"></div>
              <div className="confetti-78"></div>
              <div className="confetti-77"></div>
              <div className="confetti-76"></div>
              <div className="confetti-75"></div>
              <div className="confetti-74"></div>
              <div className="confetti-73"></div>
              <div className="confetti-72"></div>
              <div className="confetti-71"></div>
              <div className="confetti-70"></div>
              <div className="confetti-69"></div>
              <div className="confetti-68"></div>
              <div className="confetti-67"></div>
              <div className="confetti-66"></div>
              <div className="confetti-65"></div>
              <div className="confetti-64"></div>
              <div className="confetti-63"></div>
              <div className="confetti-62"></div>
              <div className="confetti-61"></div>
              <div className="confetti-60"></div>
              <div className="confetti-59"></div>
              <div className="confetti-58"></div>
              <div className="confetti-57"></div>
              <div className="confetti-56"></div>
              <div className="confetti-55"></div>
              <div className="confetti-54"></div>
              <div className="confetti-53"></div>
              <div className="confetti-52"></div>
              <div className="confetti-51"></div>
              <div className="confetti-50"></div>
              <div className="confetti-49"></div>
              <div className="confetti-48"></div>
              <div className="confetti-47"></div>
              <div className="confetti-46"></div>
              <div className="confetti-45"></div>
              <div className="confetti-44"></div>
              <div className="confetti-43"></div>
              <div className="confetti-42"></div>
              <div className="confetti-41"></div>
              <div className="confetti-40"></div>
              <div className="confetti-39"></div>
              <div className="confetti-38"></div>
              <div className="confetti-37"></div>
              <div className="confetti-36"></div>
              <div className="confetti-35"></div>
              <div className="confetti-34"></div>
              <div className="confetti-33"></div>
              <div className="confetti-32"></div>
              <div className="confetti-31"></div>
              <div className="confetti-30"></div>
              <div className="confetti-29"></div>
              <div className="confetti-28"></div>
              <div className="confetti-27"></div>
              <div className="confetti-26"></div>
              <div className="confetti-25"></div>
              <div className="confetti-24"></div>
              <div className="confetti-23"></div>
              <div className="confetti-22"></div>
              <div className="confetti-21"></div>
              <div className="confetti-20"></div>
              <div className="confetti-19"></div>
              <div className="confetti-18"></div>
              <div className="confetti-17"></div>
              <div className="confetti-16"></div>
              <div className="confetti-15"></div>
              <div className="confetti-14"></div>
              <div className="confetti-13"></div>
              <div className="confetti-12"></div>
              <div className="confetti-11"></div>
              <div className="confetti-10"></div>
              <div className="confetti-9"></div>
              <div className="confetti-8"></div>
              <div className="confetti-7"></div>
              <div className="confetti-6"></div>
              <div className="confetti-5"></div>
              <div className="confetti-4"></div>
              <div className="confetti-3"></div>
              <div className="confetti-2"></div>
              <div className="confetti-1"></div>
              <div className="confetti-0"></div>
            </div> */}
            <img src={logo} alt="logo" className="logo me-1 cursorpointer" />
            <img src={anni4} alt="anni" className="anni-img" />
          </div>
          <img src={menu} alt="kodelogo" className="menu-logo cursorpointer" as="button" onClick={() => setOpenMenu(true)} />
        </nav>

        <div className="content">
          <ul className="page-tab">
            {pageTabs.map((x, idx) => (
              <li className={selectiontab === idx ? "page active" : "page"} key={idx} id={`page${idx}`} onClick={() => changePage(x.section)}></li>
            ))}
          </ul>
          <div className="scrollbar ">
            {/* <span className="text-white mb-2">Scroll</span> */}
            <img src={selectiontab === 6 ? scrollUp : scrolldown} alt="scroll" as="a" onClick={scrollFunction} style={{ cursor: "pointer" }} />
          </div>
        </div>
      </div>

      <Section1 />
      <Section7 />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Footer show={false} />
    </div>
  );
};

export default Home;
