import React from "react";
import { Carousel } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { RiArrowLeftFill, RiArrowRightFill } from "react-icons/ri";
import union from "../../assets/img/Union.png";
import union2 from "../../assets/img/Union2.png";
import union3 from "../../assets/img/Union3.png";
import chip from "../../assets/img/microchip.png";

const carouselItems = [
  {
    id: 1,
    image: chip,
    headText: "A Decade Of Dominance",
    paraText: "11 years of relentless pursuit of technological mastery and client success stories.",
  },
  {
    id: 2,
    image: union,
    headText: "Efficiency And Economy",
    paraText: "We break the stereotypes - delivering top-tier tech solutions rapidly and within your financial comfort zone.",
  },
  {
    id: 3,
    image: union2,
    headText: "Versatility In Tech",
    paraText: "From seamless web experiences to robust mobile apps, and from intricate data analytics to advanced data science, our portfolio is as diverse as it is deep.",
  },
  {
    id: 4,
    image: union3,
    headText: "Unwavering Commitment",
    paraText: "We're not just tech experts; we're enthusiasts committed to elevating your business with bespoke solutions.",
  },
];

const CarouselItem = () => {
  // const [value, setvalue] = React.useState()
  return (
    <>
      <Carousel controls={false} indicators={false}>
        {carouselItems.map((each, idx) => (
          <Carousel.Item>
            <div className="card-carousel">
              <div className="card-item ">
                <div className="img-set">
                  <img src={each.image} alt="code" className="carousel-img" />
                </div>
                <h3 className="text-center card-head">{each.headText}</h3>
                <p className=" text-center card-para"> {each.paraText}</p>
                {/* <div className="d-flex align-items-center justify-content-center arrow-color" style={{ marginTop: "50px" }}>
                  <RiArrowLeftFill className="car-arrow" style={{ color: "#353396" }} />
                  <BsThreeDots className="dots" style={{ color: "#353396" }} />
                  <RiArrowRightFill className="car-arrow right" style={{ color: "#353396" }} />
                </div> */}
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};

export default CarouselItem;

export const Section3Carousel = () => {
  return (
    <>
      <Carousel controls={false} indicators={false}>
        {carouselItems.map((each, idx) => (
          <Carousel.Item interval={2000}>
            <div className="card-carousel">
              <div className="card-item ">
                <div className="img-set">
                  <img src={each.image} alt="code" className="carousel-img" />
                </div>
                <h3 className="text-center card-head">{each.headText}</h3>
                <p className=" text-center card-para"> {each.paraText}</p>
                <div className="d-flex align-items-center justify-content-center arrow-color">
                  <RiArrowLeftFill className="car-arrow" style={{ color: "#198754" }} />
                  <BsThreeDots className="dots" style={{ color: "#198754" }} />
                  <RiArrowRightFill className="car-arrow right" style={{ color: "#198754" }} />
                </div>
              </div>
            </div>
          </Carousel.Item>
        ))}
      </Carousel>
    </>
  );
};
