import React from "react";
import pagedown from "../../assets/img/pagedown4.png";
import pageup from "../../assets/img/pageup4.png";
import { ClientCarousel } from "./../carousels/servicecarousel";

const Section5 = () => {
  return (
    <section className="main-section  sub-section bg-container5 position-relative" id="section5">
      <div className="d-flex justify-content-between align-items-start h-100 ">
        <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1" src={pagedown} alt="up" />
        <img className="sec-one-img align-self-start flex-sm-{grow|shrink}-1" src={pageup} alt="down" />
      </div>
      <div className="content-card card-5">
        <div className="service-content text-white">
          <p>Clients</p>
          <h1 className="about-head service-head">
            Our <span className="kode kode-4">Clients</span>
          </h1>
          <p className="about-para service-para">
            KodeCorp is all about a team of young, dedicated and highly skilled professionals, whose work over the years speaks for itself.
            <br /> Established in the year 2013.
          </p>
          <ClientCarousel />
          {/* <div className="client mt-2" style={{ width: "50%" }}>
                        <h3 className="text-center fw-bold card-head" style={{ color: "black" }}>
                            "Awesome !!!!"
                        </h3>
                        <p className="text-center card-para" style={{ width: "90%" }}>
                            "Veri-Fuel is transforming the fuel industry in the US and Canada by providing end to end enterprise class sustainable software to over 1500 companies. Some of the most awaited and ambitious products are currently in development and soon to be launched! We are proud to be Veri-Fuel's trusted technology partner"
                        </p>
                    </div> */}
        </div>
      </div>
    </section>
  );
};

export default Section5;
