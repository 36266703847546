import React from "react";
import Footer from "./footer";
import link from "../assets/img/linkimg.png";
import img1 from "../assets/img/sub-servicetestmobial1.png";
import simcard from "../assets/img/sim-card.png";
import folder from "../assets/img/folder-open.png";
import aicard from "../assets/img/artificial-intelligence.png";
import could from "../assets/img/cloud-download.png";
import pagedown3 from "../assets/img/pagedown4.png";
import pageup3 from "../assets/img/pageup4.png";
import groupimg from "../assets/img/enterprise.png";
import mobileappimg from "../assets/img/mobileAppMainimg.png";
import DataScience from "../assets/img/DataScienceMainimg.png";
import Geerativeimg from "../assets/img/GenerativeMainimg.png";
import DevopsMainimg from "../assets/img/DevopsMainimg.png";
import fullteamimg from "../assets/img/fullTeamMainimg.png";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const CardsData = [
  {
    cardName: "Enterprise Software Development",
    icon: img1,
    id: 1,
    background: "#306E5B",
    textcolor: "#7CC7B0",
    tag: "Enterprise",
    h1: (
      <h1 className="service-aboutHead">
        Enterprise{" "}
        <span className="subtile" style={{ color: "#7CC7B0" }}>
          {" "}
          Software Development
        </span>
      </h1>
    ),
  },
  {
    cardName: "Full Team Services",
    icon: link,
    id: 2,
    background: "#B66E0F",
    tag: "Team Services",
    textcolor: "#F5BC71",
    h1: (
      <h1 className="service-aboutHead">
        Full{" "}
        <span className="subtile" style={{ color: "#F5BC71" }}>
          {" "}
          Team Services
        </span>
      </h1>
    ),
  },
  {
    cardName: "Mobile App Development",
    icon: simcard,
    id: 3,
    background: "#4A50A7",
    tag: "Development",
    textcolor: "#ffffff",
    h1: (
      <h1 style={{ color: "#A3A8F5" }} className="service-aboutHead">
        Mobile App{" "}
        <span className="subtile" style={{ color: "#FFFFFF" }}>
          {" "}
          Development
        </span>
      </h1>
    ),
  },
  {
    cardName: "Data Science",
    icon: folder,
    id: 4,
    background: "#6323B8",
    tag: "Data Science",
    textcolor: "#B78CEF",
    h1: (
      <h1 className="service-aboutHead" style={{ color: "#B78CEF" }}>
        Data Science
      </h1>
    ),
  },
  {
    cardName: "Generative AI",
    spanPara: "App",
    icon: aicard,
    id: 5,
    background: "#4C49CF",
    tag: "Generative AI",
    textcolor: "#A3A8F5",
    h1: (
      <h1 className="service-aboutHead">
        Generative <span style={{ color: "#9C97FF" }}>AI</span>
      </h1>
    ),
  },
  {
    cardName: "Devops And Cloud",
    icon: could,
    id: 6,
    background: "#C23B23",
    tag: "Devops & Cloud",
    textcolor: "",
    h1: (
      <h1 style={{ color: " #F39797" }} className="service-aboutHead">
        Devops <span style={{ color: "#ffffff" }}>And</span> Cloud
      </h1>
    ),
  },
];
const Data = [
  {
    id: 1,
    Mainheading: "Software Development",
    p1: "In the dynamic world of business, enterprise software is the cornerstone of growth and efficiency.",
    p2: "At KodeCorp, we understand this critical need and specialize in developing robust, scalable, and innovative enterprise software solutions.",
    p3: "Our approach begins with a deep dive into understanding your business processes, challenges, and goals.",
    p4: "This allows us to design and implement custom solutions that are not just state-of-the-art but also perfectly aligned with your business objectives.",
    p5: "From complex  ERP systems to streamlined CRM applications, we cover the entire spectrum of enterprise software needs. Our solutions are designed to integrate seamlessly into your existing infrastructure, promoting synergy and eliminating operational siloes. With a keen focus on user experience, security, and scalability, we ensure that our software not only meets today’s needs but is also future-proof. ",
    p6: "Our team, composed of industry veterans and innovative thinkers, brings a wealth of experience and a fresh perspective to each project. We pride ourselves on our collaborative approach, working closely with clients to ensure that every aspect of the software reflects their vision and requirements.",
    p7: "Through our commitment to excellence and a relentless pursuit of customer satisfaction, KodeCorp has become a trusted partner for businesses looking to leverage the power of enterprise software for sustainable growth and success.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">.Net</button>
        <button className="serv-btn">Angular</button>
        <button className="serv-btn">React</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">AWS</button>
        <button className="serv-btn">Azure</button>
        <button className="serv-btn">Google Cloud</button>
      </div>
    ),
  },
  {
    id: 2,
    Mainheading: "Full Team Services",
    p1: "At KodeCorp, we offer comprehensive Full Team Services, providing you with a dedicated team of experts tailored to your project’s unique requirements.",
    p2: "This service encompasses a wide range of professionals including project managers, software developers, UI/UX designers, QA testers, and support staff, ensuring a holistic approach to your project. ",
    p3: "Our full team services are designed to offer flexibility and scalability, adapting to your project's evolving needs. Whether you require a complete team for a new project or additional expertise to supplement your existing workforce, we offer customizable solutions that align with your goals and timelines.",
    p4: " The benefits of our full team services are manifold. By having a dedicated team, you gain access to a diverse range of skills and expertise under one roof. This integrated approach not only streamlines communication and project management but also ensures consistency and quality across all aspects of your project.  ",
    p5: " We pride ourselves on our collaborative approach, where we work as an extension of your in-house team. Regular updates, transparent communication, and a shared project vision are the hallmarks of our service. With KodeCorp’s Full Team Services, you’re not just hiring a team; you’re partnering with professionals who are committed to your project’s success.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">React</button>
        <button className="serv-btn">React Native</button>
        <button className="serv-btn">Node .JS</button>
      </div>
    ),
  },
  {
    id: 3,
    Mainheading: "Mobile App Development",
    p1: "In today’s fast-paced digital world, mobile apps have become essential for connecting with customers and optimizing business processes.",
    p2: "KodeCorp specializes in developing high-quality, custom mobile applications that cater to your unique business needs. Our expertise spans across all major platforms, including iOS and Android, ensuring your app has the broadest possible reach.",
    p3: "Our development process is thorough and client-centric. It begins with understanding your vision, followed by meticulous planning, design, development, testing, and deployment.",
    p4: "We place a strong emphasis on user experience and interface design, ensuring that your app is not only functional but also intuitive and engaging.",
    p5: "Our team stays abreast of the latest trends and technologies in mobile app development, ensuring that your app is innovative and future-proof. Whether it's integrating advanced features like AI, AR, or IoT, or ensuring seamless performance across devices, our developers are equipped to handle the most complex of challenges.",
    p6: "We also understand the importance of post-launch support and maintenance, offering services to ensure your app remains up-to-date and continues to deliver value to your users. With KodeCorp, you get more than just a mobile app; you get a strategic partner committed to helping you achieve digital success.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">React Native</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">React JS</button>
        <button className="serv-btn">IOS</button>
        <button className="serv-btn">Android</button>
      </div>
    ),
  },
  {
    id: 4,
    Mainheading: "Data Science",
    p1: "Data is the lifeblood of modern business, and at KodeCorp, we harness its power through cutting-edge data science techniques.",
    p2: "Our services range from data analytics and visualization to predictive modeling and machine learning, enabling businesses to unlock valuable insights from their data.",
    p3: "We begin by understanding your data landscape and objectives, followed by employing sophisticated algorithms and analytics tools to extract meaningful patterns and trends.",
    p4: "Our data science solutions are tailored to provide actionable insights, supporting decision-making, optimizing operations, and identifying new opportunities.",
    p5: "Our team of data scientists, analysts, and engineers are adept at handling large data sets and complex analysis, ensuring accuracy and reliability in our findings. Whether you're looking to improve customer experiences, streamline processes, or drive innovation, KodeCorp’s data science services are your gateway to data-driven success.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">Data</button>
        <button className="serv-btn">Angular</button>
        <button className="serv-btn">React</button>
        <button className="serv-btn">Node JS</button>
        <button className="serv-btn">AWS</button>
      </div>
    ),
  },
  {
    id: 5,
    Mainheading: "Generative AI",
    p1: "Generative AI represents the forefront of artificial intelligence, where machines don’t just analyze data but create new content.",
    p2: "KodeCorp is at the cutting edge of this exciting field, offering solutions that range from automated content generation to advanced data modeling and simulation.",
    p3: "Our generative AI capabilities enable businesses to automate creative processes, generate novel ideas, and model complex scenarios.",
    p4: "This technology has applications across various sectors, including marketing, design, research, and development. ",
    p5: "We leverage the latest advancements in AI, including neural networks and deep learning, to develop solutions that are both innovative and practical. With our expertise in generative AI, KodeCorp empowers businesses to explore new horizons and push the boundaries of what’s possible.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">AI</button>
        <button className="serv-btn">Amazon Web Services</button>
        <button className="serv-btn">Android</button>
      </div>
    ),
  },
  {
    id: 6,
    Mainheading: "Devops And Cloud",
    p1: "In the modern digital landscape, agility and efficiency are key.",
    p2: "KodeCorp’s DevOps and Cloud services are designed to streamline and optimize your software development lifecycle.",
    p3: "We integrate development and operations to achieve faster deployment, higher quality, and more reliable software.",
    p4: "Our approach involves continuous integration, continuous delivery, automated testing, and proactive monitoring, ensuring a seamless and efficient workflow. We also specialize in cloud computing services, offering scalable and secure cloud infrastructure solutions that are tailored to your specific needs.",
    p5: "Whether it's migrating to the cloud, optimizing existing cloud environments, or implementing DevOps practices, our team has the expertise to guide your journey. With KodeCorp, you can leverage the full potential of DevOps and Cloud to accelerate innovation and growth in your business.",
    button: (
      <div className="text-center mt-4">
        <button className="serv-btn">AWS</button>
        <button className="serv-btn">Zenkins</button>
        <button className="serv-btn">Azure Application Insights</button>
      </div>
    ),
  },
];
const MainheadingData = [
  { id: 1, p: "Tailoring robust software architectures to empower your business operations.", img: groupimg },
  { id: 2, p: "Your project, our dedicated team - a blend of skills and passion at your service.", img: fullteamimg },
  { id: 3, p: "Where innovation meets the user's fingertips in iOS and Android applications.", img: mobileappimg },
  { id: 4, p: "Unraveling the stories hidden in data, turning insights into action.", img: DataScience },
  { id: 5, p: "Pioneering the new frontier of AI to create, innovate, and revolutionize.", img: Geerativeimg },
  { id: 6, p: "Orchestrating seamless software lifecycles and cloud transformations for agility and efficiency.", img: DevopsMainimg },
];
const ServiceSubPage = () => {
  const location = useLocation();
  const [value, setValue] = React.useState(location.state.id);

  const onChange = (id) => {
    setValue(id);
    window.scrollTo(0, 0);
  };
  return (
    <>
      <section className={`sub-container container${value}`}>
        <div className="d-flex justify-content-between align-items-start h-100 ">
          <img className="sec-one-img align-self-end flex-sm-{grow|shrink}-1 sub-service-corner" src={pagedown3} alt="up" />
          <img className="sec-one-img align-self-start flex-sm-{grow|shrink}-1 sub-service-corner" src={pageup3} alt="down" />
        </div>
        <div className="service-content-card card-3 col-12">
          <div className="sub-service-content text-white deliever-content col-7 col-md-6 col-lg-5">
            {/* <h1 className="service-aboutHead">{CardsData[value - 1].para} <span className="subtile" style={{ color: CardsData[value - 1].textcolor }}>{CardsData[value - 1].spanPara}</span></h1> */}
            {CardsData[value - 1].h1}
            <hr className="horizon" />
            <p className="service-aboutPara"> {MainheadingData[value - 1].p}</p>
          </div>
          <div className="col-4 col-md-3 col-lg-4 d-flex justify-content-center align-items-center">
            <img src={MainheadingData[value - 1].img} alt="service" className="service-sub-img " />
          </div>
        </div>
      </section>
      <div className="d-flex justify-content-center w-100">
        <div className="serviceContent ">
          <div className="sub position-relative h-100">
            <div className="sub-service-page ">
              {/* <div class="text-container">
                <div class="container-text">Enterprisecfvcbvcbvc vngf fhfg</div>
              </div> */}

              <h3 className="sub-service-main-heading" style={{ color: CardsData[value - 1].background }}>
                {Data[value - 1].Mainheading}
              </h3>
              <div className="bg-white  inner-sub-para">
                <p className="sub-service-paragraph " style={{ marginTop: "15px" }}>
                  {Data[value - 1].p1}
                </p>
              </div>

              <div className="bg-white   inner-sub-para">
                <p className="sub-service-paragraph">{Data[value - 1].p2}</p>
              </div>
              <div className="bg-white   inner-sub-para">
                <p className="sub-service-paragraph">{Data[value - 1].p3}</p>
              </div>
              <p className="sub-service-paragraph text-start mt-2">{Data[value - 1].p4}</p>
              <p className="sub-service-paragraph text-start mt-2">{Data[value - 1].p5}</p>
              <p className="sub-service-paragraph text-start mt-2">{Data[value - 1].p6}</p>
              <p className="sub-service-paragraph text-start mt-2">{Data[value - 1].p7}</p>
              {/* {Data[value - 1].button} */}
            </div>
            <div className="position-absolute top-0 topTri" style={{ borderColor: `${CardsData[value - 1].background} transparent transparent transparent` }}></div>
            <p className="position-absolute text-white tag">{CardsData[value - 1].tag}</p>
          </div>
          <div className="cards">
            {CardsData.map((e, idx) => {
              return (
                <div className={value === e.id ? "card-content" : "card-content slide"}>
                  {value === e.id && <div className="triangle_left" style={{ borderColor: `  transparent ${e.background} transparent transparent` }}></div>}
                  {value === e.id && <div className="triangle_down" style={{ borderColor: ` ${e.background} transparent  transparent transparent` }}></div>}
                  <div
                    key={e.id}
                    className="cardclickstyle"
                    style={{ backgroundColor: e.background }}
                    onClick={() => {
                      setValue(e.id);
                    }}
                  >
                    <img src={e.icon} alt="img1" className="subCard-img" />
                    <p className="text-white card-para1">{e.cardName}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Footer show={true} onChange={onChange} />
    </>
  );
};
export default ServiceSubPage;
