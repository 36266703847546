import React, { useRef, useState } from "react";
import calling from "../assets/img/callng.png";
import messaging from "../assets/img/messaging.png";
import Footer from "./footer";
import emailjs from "@emailjs/browser";
import Toast from "react-bootstrap/Toast";

const Contact = () => {
  const [successText, setSuccessText] = useState({ text_msg: "", success: true });
  const [show, setShow] = useState(false);
  const form = useRef("");
  const name = useRef();
  const mail = useRef();
  const message = useRef();
  const phoneNumber = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (mail.current.value.match(validRegex)) {
      emailjs.sendForm("service_a0h4nzn", "template_nrp4itk", form.current, "N1CawNJN4rEPZKNFZ").then(
        (result) => {
          console.log(result.text);
          if (result.text === "OK") {
            setSuccessText({ ...successText, text_msg: "Successfully Submitted", success: true });
            setShow(true);
          }
        },
        (error) => {
          console.log(error.text);
          setSuccessText({ ...successText, text_msg: "Something Error", success: false });
          setShow(true);
        }
      );
    } else {
      setSuccessText({ ...successText, text_msg: "Invalid Email Address", success: false });
      setShow(true);
    }
    name.current.value = "";
    mail.current.value = "";
    message.current.value = "";
    phoneNumber.current.value = "";
  };
  return (
    <>
      <section className="position-relative">
        <div className="contact-bg-cont"></div>
        <div className="welcome"></div>
        <div className="position-absolute z-5 d-flex flex-column align-items-center justify-content-center w-100 welContent ">
          <div>
            <div>
              <h1 className="aboutHead">Get In Touch</h1>
              <hr className="horizon" />
            </div>
            <p className="aboutPara">
              Kode Corp is all about a team of young, dedicated, and highly skilled professionals, whose work over the <br />
              years speaks for itself. Established in the year 2013.
            </p>
          </div>
        </div>
        <div className="d-flex flex-column align-items-center contact">
          <div className="contact-element row col-12 col-lg-12">
            <div className="col-12 col-lg-6 mobile-contact service-Contactinfo contact-call mb-2">
              <div className="position-relative">
                <img src={calling} alt="call" width={80} className="msg-icon"></img>
                <h4 className="service-contact">Contact Us at :</h4>
                <h4 className="service-numberinfo">+91 (40) 2956 2666, +91 630 268 489</h4>
              </div>
            </div>
            <div className="col-12 col-lg-6 mobile-contact service-Contactinfo contact-msg mb-2">
              <div className="position-relative">
                <img src={messaging} alt="message" width={80} className="msg-icon second-msg-icon"></img>
                <h4 className="service-contact">E-Mail : </h4>
                <h4 className="service-numberinfo">contactus@kodecorp.com</h4>
              </div>
            </div>
          </div>
          <div className="form-bg-cont">
            <div style={{ width: "100%" }}>
              <h1 className="form-head">Our Team Is Here To Help You !</h1>
              <p className="form-para">Please fill out this form below to let us know about your service request in detail so that we can resolve your query at the earliest.</p>
            </div>
            <form className="d-flex flex-column gap-4 form-set" ref={form} onSubmit={sendEmail}>
              <div className="input-column">
                <input type="text" placeholder="Your Name *" className="contact-input " name="user_name" ref={name} required />
                <input type="text" placeholder="Email address *" className="contact-input " name="user_email" ref={mail} required />
              </div>
              <div className="input-column">
                <input type="text" placeholder="Phone number *" className="contact-input" ref={phoneNumber} name="user_phone" />
                <input type="text" placeholder="Website" className="contact-input" />
              </div>
              <textarea type="text" placeholder="Your Message *" className="contact-input input-msg" name="user_message" ref={message} required />
              <button className="send-btn" type="submit" value="Send" id="liveToastBtn">
                Send Now
              </button>
            </form>
            <div className="d-flex justify-content-center w-100">
              <Toast onClose={() => setShow(false)} show={show} delay={2000} autohide bg={successText.success ? "success" : "danger"} position="bottom-center" className="fade-in-toast">
                <Toast.Body style={{ color: "white", fontWeight: "bold" }}>{successText.text_msg}</Toast.Body>
              </Toast>
            </div>
          </div>
          <iframe
            title="kodecorp"
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15224.518886826336!2d78.3700743!3d17.4535042!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb911bb9f7889d%3A0x5c3f9e5ddfb94f88!2sKodeCorp%20Technology%20Solutions!5e0!3m2!1sen!2sin!4v1700806025025!5m2!1sen!2sin"
            className="map"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </section>
      <Footer show={true} />
    </>
  );
};

export default Contact;
